import { deepEqual } from 'fast-equals'

/**
 * Check if two objects are equivalent.
 *
 * @param a - An object to compare.
 * @param b - Another object to compare.
 * @returns The result of a fast deep equal comparison.
 */

export default function useDeepEqual<T>(a: T, b: T) {
  return deepEqual<T, T>(a, b)
}
